import React, { useState, useCallback, useEffect } from 'react';
import { Card, CardContent } from '@mui/material';
import { Select, MenuItem, InputLabel } from "@mui/material";
import { TextField } from "@mui/material";
import { Button } from "antd";
import { Loader2 } from 'lucide-react';
import { Alert } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, PieChart, Pie, Cell, Legend } from 'recharts';
import { SendImageEmail } from './SendImageEmail'
import { useRef } from 'react';
import { trackEvent } from '../utils/analytics';


//import mealData from './mealData.json'; // Adjust the path if necessary
import '../dist/output.css';
import logo from '../assets/images/logo.png';



const SummaryBox = ({ calories, protein, carbs, fat }) => (
  <div className="grid grid-cols-4 gap-4 p-4 mb-8 bg-porcelain-100 rounded-lg">
    <div className="text-center">
      <p className="value text-2xl font-bold text-porcelain-800">{calories}</p>
      <p className="label text-sm text-porcelain-600">Calories</p>
    </div>
    <div className="text-center">
      <p className="value text-2xl font-bold text-porcelain-800">{protein}g</p>
      <p className="label text-sm text-porcelain-600">Protein</p>
    </div>
    <div className="text-center">
      <p className="value text-2xl font-bold text-porcelain-800">{carbs}g</p>
      <p className="label text-sm text-porcelain-600">Carbs</p>
    </div>
    <div className="text-center">
      <p className="value text-2xl font-bold text-porcelain-800">{fat}g</p>
      <p className="label text-sm text-porcelain-600">Fat</p>
    </div>
  </div>
);



const MealRow = ({ title, emojis, description, macros, micros }) => (
  <div className="MealRow mb-8 p-4 bg-porcelain-100 rounded-md border-l-4 border-porcelain-400 shadow-sm">
    <h3 className="text-lg font-semibold mb-2 text-porcelain-800">{title}</h3>
    <div className="flex flex-col gap-2">
      <div className="flex gap-4">
        {emojis.map((emoji, index) => (
          <span key={index} className="text-2xl">{emoji}</span>
        ))}
      </div>
      <div>
        <p className="text-sm text-porcelain-900">{description}</p>
        <p className="text-xs text-porcelain-700 mt-1">{macros}</p>
        <p className="text-xs text-porcelain-600">{micros}</p>
      </div>
    </div>
  </div>
);

const NutrientCharts = ({ macros, mealCalories }) => {
  const macroData = [
    { name: 'Protein', value: macros.protein, color: '#FFD24C' },
    { name: 'Carbs', value: macros.carbs, color: '#FFD592' },
    { name: 'Fat', value: macros.fat, color: '#E6BD41' }
  ];
  
  return (
    <div className="grid grid-cols-1 gap-6 mb-8 bg-porcelain-600 p-20 rounded-md">
      <div className="h-64 mt-6">
        <h3 className="text-2xl font-bold mb-6 text-white">Your Daily Breakdown</h3>
        <p className="text-sm text-white mb-2  font-bold">Macros Distribution</p>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={macroData}
              dataKey="value"
              cx="50%"
              cy="50%"
              labelLine={false}
              label={({ name, value }) => `${name}: ${value}g`}
              outerRadius={80}
              //fill="#8884d8"
              
            >
              {macroData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Legend />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="h-64 mt-8 mb-8">
        <p className="text-sm text-white mb-2 mt-10 font-bold">Calories per Meal</p>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={mealCalories}>
            <XAxis dataKey="name" stroke="#FFEDCE" tick={{fontSize:10}} interval={0} textAnchor= "end" sclaeToFit="true" angle= "-20"  />
            <YAxis stroke="#FFEDCE" />
            <Tooltip />
            <Bar dataKey="calories" fill="#FFD24C" /> 
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const CalorieInput = ({ value, onChange, error }) => (
  <div className="flex-1">
    <TextField
      id="calorie-input"
      type="text"
      inputMode="numeric"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder="Enter daily calories"
      className={`w-full ${error ? 'border-red-500' : ''}`}
    />
    {error && (
      <p className="text-red-500 text-xs mt-1">{error}</p>
    )}
  </div>
);

const DietSelector = ({ selectedDiet, onDietChange, calories, onCaloriesChange, onGenerate, errors }) => (
  <div className="mb-6 justify-items-left">
    <div className="flex gap-4">
      <div className="flex-1">
        <InputLabel id="diet-select-label">Your Diet</InputLabel>
        <Select style={{minWidth:220}}
          labelId="diet-select-label"
          id="diet-select"
          value={selectedDiet} 
          label="Select Diet"
          onChange={(e) => onDietChange(e.target.value)}
          >
          <MenuItem value={"Balanced Diet"}>Regular Balanced Diet</MenuItem>
          <MenuItem value={"Keto"}>Ketogenic</MenuItem>
          <MenuItem value={"Paleo"}>Paleo</MenuItem>
          <MenuItem value={"Vegetarian"}>Vegetarian</MenuItem>
          <MenuItem value={"Vegan"}>Vegan</MenuItem>
        </Select>
      </div>
      <div>
        <InputLabel id="calorie-input-label">Daily Calories</InputLabel>
        <CalorieInput 
          labelId="calorie-input-label"
          value={calories}
          onChange={onCaloriesChange}
          error={errors.calories}
        />
      </div>
      </div>
      <div className="mt-3">
      <Button 
        onClick={onGenerate}
        className="bg-porcelain-600 !hover:bg-porcelain-700 text-white h-10 rounded-md inline-flex"
        disabled={Boolean(errors.diet || errors.calories)}
      >
        Generate Plan
      </Button>
    </div>
    {(errors.diet || errors.calories) && (
      
      <Alert severity="error">
         Please fix the errors above before generating your meal plan.
      </Alert>
      
    )}
  </div>
);





const NutritionPlan = () => {
  const [selectedDiet, setSelectedDiet] = useState('');
  const [calories, setCalories] = useState('');
  const [mealPlan, setMealPlan] = useState(null);
  const [macros, setMacros] = useState({ protein: 0, carbs: 0, fat: 0 });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const mealPlanRef = useRef(null);
  const [email, setEmail] = useState('');
  



  const calculateMacros = (meals) => {
    let totalProtein = 0;
    let totalCarbs = 0;
    let totalFat = 0;

    meals.forEach(meal => {
      const [, p, c, f] = meal.macros.match(/\d+/g).map(Number);
      totalProtein += p;
      totalCarbs += c;
      totalFat += f;
    });

    return {
      protein: totalProtein,
      carbs: totalCarbs,
      fat: totalFat
    };
  };

  const handleDietChange = (value) => {
    setSelectedDiet(value);
    const newErrors = { ...errors };
    delete newErrors.diet;
    setErrors(newErrors);
  };

  const handleCaloriesChange = (value) => {
    setCalories(value);
    const newErrors = { ...errors };
    delete newErrors.calories;
    setErrors(newErrors);
  };

  const validateInputs = () => {
    const newErrors = {};

    if (!selectedDiet) {
      newErrors.diet = 'Please select a diet type';
    }

    if (!calories) {
      newErrors.calories = 'Please enter your daily calorie target';
    } else {
      const calorieNum = Number(calories);
      if (isNaN(calorieNum)) {
        newErrors.calories = 'Please enter a valid number';
      } else if (calorieNum < 1000) {
        newErrors.calories = 'Daily calories must be at least 1,000';
      } else if (calorieNum > 5000) {
        newErrors.calories = 'Daily calories must not exceed 5,000';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  /*const handleEmailChange = useCallback((e) => {
    setEmail(e.target.value);
  }, []);*/

  
  /* GENERATE FROM API CALL */
  const handleGenerate = async () => {
    if (!validateInputs()) return;
    trackEvent('User', 'Click', 'Generate Meal Plan');

    setIsLoading(true);
    setApiError(null);

    try {
      console.log(`${process.env.REACT_APP_API_URL}/api/generate-meal-plan`);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/generate-meal-plan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_API_SECRET,
        },
        body: JSON.stringify({
          dietType: selectedDiet,
          calories: Number(calories)
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const meals = await response.json();
      const newMacros = calculateMacros(meals);
      
      setMacros(newMacros);
      setMealPlan(meals);
    } catch (error) {
      setApiError('Failed to generate meal plan. Please try again.');
      console.error('Error generating meal plan:', error);
    } finally {
      setIsLoading(false);
    }
};

  
  /* GENERATE FROM STATIC JSON */
  /*const handleGenerate = () => {
      if (!validateInputs()) return;

      setIsLoading(true);
      setApiError(null);


      try {
        // Filter or adjust the meal data based on selectedDiet or calories
        //const filteredMeals = mealData; // Add filtering logic here if needed
        const dietMeals = mealData[0][selectedDiet]
        console.log("dietMeals is "+dietMeals);

        const newMacros = calculateMacros(dietMeals);

        setMacros(newMacros);
        setMealPlan(dietMeals);
      } catch (error) {
        setApiError('Failed to generate meal plan. Please try again.');
        console.error('Error generating meal plan:', error);
      } finally {
        setIsLoading(false);
      }
  };*/


  const getMealCalories = () => {
    if (!mealPlan) return [];
    return mealPlan.map(meal => ({
      name: meal.title,
      calories: parseInt(meal.macros.match(/\d+/)[0])
    }));
  };

 
  

  return (
    <div className="flex justify-items-center align-items-center">
      <div className="w-full max-w-[600px]">
        <Card className="w-full max-w-4xl mx-auto bg-porcelain-100">
          <CardContent className="p-6 !bg-porcelain-50">
            <img src={logo} alt="feed me syemore"  width="175" className="mt-6"/>
            <h3 className="text-sm font-semibold mb-6 text-yellow-700">Your AI Meal Plan Creator</h3>
            
            <DietSelector 
              selectedDiet={selectedDiet}
              onDietChange={handleDietChange}
              calories={calories}
              onCaloriesChange={handleCaloriesChange}
              onGenerate={handleGenerate}
              errors={errors}
              isLoading={isLoading}
            />
            
            {apiError && (
              <Alert severity="error">
                {apiError}
              </Alert>
            )}

            {isLoading && (
              <div className="flex flex-col items-center justify-center py-12">
                <Loader2 className="h-8 w-8 animate-spin text-porcelain-600" />
                <p className="mt-4 text-porcelain-800">Generating your custom meal plan...</p>
              </div>
            )}
            
            {mealPlan && !isLoading && (
              
              <>
              <div ref={mealPlanRef}>  
                <SummaryBox 
                  calories={calories}
                  protein={macros.protein}
                  carbs={macros.carbs}
                  fat={macros.fat}
                />
                <div className="grid grid-cols-2 gap-4 ">
                
                {mealPlan.map((meal, index) => (
                  <MealRow key={index} {...meal} />
                ))}


                <SendImageEmail
                  selectedDiet={selectedDiet}
                  calories={calories}
                  macros={macros}
                  mealPlan={mealPlan}
                />
                
                </div>
                </div> 
                <div className="border-t border-porcelain-200 pt-8">
                  <NutrientCharts macros={macros} mealCalories={getMealCalories()} />
                </div>
              </>


            )}

            
          </CardContent>
        </Card>
      </div>
    </div>

  );
};

export default NutritionPlan;