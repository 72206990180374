import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { TextField } from "@mui/material";
import { Button } from "antd";
import { Loader2 } from 'lucide-react';
import { createRoot } from 'react-dom/client';
import { trackEvent } from '../utils/analytics';



const EmailMealPlan = ({ dietType, calories, macros, mealPlan }) => (

  <div className="p-6 bg-white max-w-[600px] font-sans">
    <div className="bg-porcelain-50 p-4 rounded-lg mb-6">
      <h1 className="text-2xl font-bold text-center text-porcelain-800 mb-2">{dietType} Meal Plan</h1>
      <p className="text-center text-porcelain-700 font-bold">{calories} calories</p>
      <div className="flex justify-center gap-8 mt-4 text-porcelain-600">
        <p>Protein: {macros.protein}g</p>
        <p>Carbs: {macros.carbs}g</p>
        <p>Fat: {macros.fat}g</p>
      </div>
    </div>

    <div className="space-y-6">
      {mealPlan.map((meal, index) => (
        <div key={index} className="border-l-4 border-porcelain-400 bg-porcelain-50 p-4 rounded-r-lg">
          <h2 className="font-bold text-porcelain-800 text-lg mb-2">{meal.title}</h2>
          <div className="text-2xl mb-2">{meal.emojis.join(' ')}</div>
          <p className="text-porcelain-700 mb-1">{meal.description}</p>
          <p className="text-sm text-porcelain-600">{meal.macros}</p>
          <p className="text-xs text-porcelain-500 mt-1">{meal.micros}</p>
        </div>
      ))}
    </div>

    <div className="mt-6 text-center text-xs text-porcelain-600">
      Generated at {new Date().toLocaleDateString()}
    </div>
  </div>
);


export const SendImageEmail = ({selectedDiet, calories, macros, mealPlan }) => {
	 const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isSending, setIsSending] = useState(false);
  //const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');



  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    //setSuccessMessage('');
    setErrorMessage('');
  };

  const handleSendEmail = async () => {
	  if (!email) return;
	  
	  try {
	    setIsSending(true);	    	
	    trackEvent('User', 'Click', 'Send Plan to Email');
	    // Clear existing messages at the start
  		//setSuccessMessage('');
  		setErrorMessage('');

	    // Create container
    const container = document.createElement('div');
    container.style.padding = '20px';
    container.style.backgroundColor = 'white';
    document.body.appendChild(container);
    
    // Render meal pla component
    const emailRoot = createRoot(container);
    emailRoot.render(<EmailMealPlan 
	        dietType={selectedDiet}
	        calories={calories}
	        macros={macros}
	        mealPlan={mealPlan}
	      />);
    
    // Wait for render
    await new Promise(resolve => setTimeout(resolve, 2000));
    
    // Try to capture
    const canvas = await html2canvas(container, {
      logging: true,
      backgroundColor: null,
      scale: 1,
      windowWidth: 600,
      windowHeight: container.offsetHeight
    });
	    
    //Clean up
    document.body.removeChild(container);
    emailRoot.unmount();

    // Convert to compressed JPEG
    const imageData = canvas.toDataURL('image/jpeg', 0.8);
    const base64Image = imageData.split(',')[1]; 
    console.log(base64Image);
    
    console.log('testing send grid');
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/send-meal-plan`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        mealPlanImage: base64Image,
        dietType: selectedDiet,
        calories
      }),
    });
	    
    if (!response.ok) throw new Error('Failed to send email');
    	// Navigate to thank you page on success
			navigate('/thank-you', { 
				  state: { 
				    email,
				    dietType: selectedDiet 
				  }
				});
    	//setSuccessMessage('Done! Check your Inbox :)');
	    console.log('Meal plan sent to your email!');
	  } catch (error) {
	  	setErrorMessage('Something went wrong :( try again.');
	    console.error('Error sending email:', error);
	  } finally {
	    setIsSending(false);
	  }
	};


return (
  <div className="email-form mt-6">
   <h4 className="text-lg font-semibold text-yellow-800 mb-4">Send Your Plan via Email</h4>
    <TextField
    	sx={{
		    '& .MuiOutlinedInput-root': {
		      '&.Mui-focused fieldset': {
		        borderColor: '#FDBA74',  // Custom border color when focused
		      },
		    },
		    '& label.Mui-focused': {
		      color: '#FDBA74',  // Custom label color when focused
		    },
  		}}

      label="Email Address"
      variant="outlined"
      value={email}
      onChange={handleEmailChange}
      fullWidth
      className="mb-4"
    />
    <Button
    	sx={{
		    '&:hover': {
		      backgroundColor: '#FB923C!important',  // Your custom hover color
		    }
		  }}	
      onClick={handleSendEmail}
      disabled={isSending || !email}
      variant="contained"
      fullWidth
      className="mt-4 bg-yellow-500 text-porcelain-900 font-semibold border-porcelain-100"
    >
      {isSending ? (
        <>
          <Loader2 className="w-4 h-4 mr-2 animate-spin" />
          Sending...
        </>
      ) : (
        'Send Email'
      )}
     </Button>
    {/*successMessage && <p className="text-green-600 mt-2">{successMessage}</p>*/}
    {errorMessage && <p className="text-red-600 mt-2">{errorMessage}</p>}
  </div>
	);
};

//export default SendImageEmail;
