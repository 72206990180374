import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import NutritionPlan from './components/NutritionPlan';
import { ThankYou } from './components/ThankYou';

import { BrowserRouter as Router, useLocation, Routes, Route } from 'react-router-dom';
import { initGA, trackPageView } from './utils/analytics';

function App() {

  useEffect(() => {
    initGA('G-R3EN6X0S78'); // Replace with your Measurement ID
  }, []);

  return (
    <>
      <Helmet>
        <title>Feed Me, Seymore</title>
      </Helmet>
      <Router>
        <TrackRouteChanges>
          <Routes>
            <Route path="/" element={
              <div>
                <NutritionPlan />
              </div>
            }/>
            <Route path="/thank-you" element={<ThankYou />} />
          </Routes>
        </TrackRouteChanges>
      </Router>
    </>
  );
}

// Tracks page views on route change
const TrackRouteChanges = ({children}) => {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  return children;
};


export default App;