import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { CheckCircle } from 'lucide-react';
import { Helmet } from 'react-helmet';

export const ThankYou = () => {
  const location = useLocation();
  const { email, dietType } = location.state || {};

  return (
    <>
      <Helmet>
        <title> Thank You Friend | Seymore App </title>
      </Helmet>
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-yellow-50 to-orange-50 p-4">
        <div className="max-w-md w-full space-y-8 bg-white rounded-2xl shadow-xl p-8 animate-fade-up">
         <div className="text-center">
            {/* Success Icon */}
            <div className="flex justify-center mb-6">
              <div className="rounded-full bg-green-100 p-3 animate-bounce">
                <CheckCircle className="w-12 h-12 text-green-500" />
              </div>
            </div>
          

          {/* Title */}
            <h1 className="text-3xl font-bold text-yellow-800 mb-6">
              Thank You!
            </h1>

            {/* Message */}
            <div className="space-y-4">
              {email && dietType ? (
                <>
                  <p className="text-gray-600 text-lg">
                    Your {dietType} meal plan has been sent to:
                  </p>
                  <p className="text-yellow-800 font-semibold text-lg bg-yellow-50 py-2 px-4 rounded-lg inline-block">
                    {email}
                  </p>
                  <p className="text-gray-600 mt-4">
                    Please check your inbox for your personalized meal plan!
                  </p>
                </>
              ) : (
                <p className="text-gray-600 text-lg">
                  Your meal plan has been sent successfully!
                </p>
              )}
            </div>

            {/* Back Button */}
            <Link 
              to="/"
              className="mt-8 inline-block px-8 py-3 bg-yellow-500 text-white font-semibold rounded-lg 
                       shadow-md hover:bg-yellow-600 transform hover:-translate-y-0.5 transition-all 
                       duration-150 ease-in-out active:shadow-sm active:translate-y-0"
            >
              Back to Home
            </Link>

            {/* Additional Info */}
            <p className="mt-6 text-sm text-gray-500">
              Didn't receive the email? Check your spam folder or contact support.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};